<template>
  <div class="input-page">
    <StepProcess :active="1" />
    <div class="input-page__container" v-if="!upload">
      <div class="d-flex box box__text" v-if="type === 'encode'">
        <div class="left">Text input</div>
        <div class="right">
          <div class="textarea-box">
            <v-textarea
              class="textarea"
              placeholder="Your text here"
              v-model="textarea"
              counter="500"
              rows="3"
              :rules="[
                v =>
                  (v || '').length < 500 ||
                  'Сharacter limit reached. Text must be 500 characters or less'
              ]"
            ></v-textarea>
          </div>
          <v-btn
            class="button-send"
            depressed
            @click="onSend"
            :disabled="textarea.length > 500 || textarea.length === 0"
          >
            Send
          </v-btn>
        </div>
      </div>
      <div class="divline" v-if="type === 'encode'">
        <span class="or">
          OR
        </span>
      </div>
      <div class="d-flex box" @drop="drop">
        <div v-if="errorFasta">Please upload only fasta files here.</div>
        <div class="left">File Upload</div>
        <div class="right">
          <v-btn
            class="text-none file-button"
            depressed
            @click="$refs.file.click()"
          >
            Choose file
          </v-btn>

          <div class="flex">
            <div class="info">
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="d-none"
                @change="onChange"
                ref="file"
                :accept="type === 'encode' ? '.txt' : '.fasta'"
              />

              <label
                for="assetsFieldHandle"
                class="block cursor-pointer"
                v-if="type == 'encode'"
              >
                <div>
                  Your input file must be smaller than 50KB for this demo
                  version of Olli. Future versions will allow for larger file
                  size for encoding.
                  <!-- Drag a file here to upload -->
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-page__container case-2" v-else>
      <h1 class="headline">File Upload</h1>
      <div class="about-input" v-if="file !== null || textarea.length > 0">
        <div class="about-input__title">About your input</div>
        <div class="about-input__type">
          {{ file !== null ? file.name : `${textarea.length} characters` }}
        </div>
        <div class="about-input__size">{{ fileSize }}</div>
      </div>
      <div class="wrapper-button">
        <v-btn class="text-none" depressed @click="onFileProgress">
          {{ type }}
        </v-btn>
      </div>
    </div>
    <div @click="back">
      <BackButton :backLink="upload ? '/input-page' : '/'" />
    </div>
  </div>
</template>
<script>
import BackButton from '@/components/BackButton';
import StepProcess from '@/components/StepProcess';
import filesize from '../../node_modules/filesize/lib/filesize.es6.js';
import { bus } from '../bus.js';

export default {
  name: 'InputPage',
  components: { BackButton, StepProcess },
  data: () => ({
    selectedFile: null,
    upload: false,
    errorFasta: false,
    textarea: '',
    file: null,
    isTextarea: false
  }),
  computed: {
    type() {
      return this.$route.query?.type || 'encode';
    },
    buttonText() {
      return this.selectedFile
        ? this.selectedFile.name
        : this.defaultButtonText;
    },
    fileSize() {
      const size = this.file?.size || this.textarea.length;
      return filesize(size);
    }
  },
  methods: {
    onChange() {
      if (this.type === 'encode') {
        this.file = this.$refs.file.files[0] || null;
        if (!this.file) return
        window.uploadFile = this.file;
        const acceptList = ['txt']
        if (!acceptList.some(type => this.file.name.includes(type))) {
          console.error('Inappropriate type of the file')
          this.$vToastify.error({
            body: `Please, upload .txt file`,
            title: ' ',
            duration: 2000,
            icon: ' ',
            type: 'error'
          });
          return
        }
        this.upload = true;
      } else if (this.type === 'decode') {
        this.file = this.$refs.file.files[0] || null;
        if (!this.file) return
        const extension = this.file?.name.split('.').pop();
        if (extension === 'fasta') {
          window.uploadFile = this.file;
          if (this.file) {
            this.upload = true;
          }
        } else {
          console.log('error only fasta');
          this.$vToastify.error({
            body: `Please, upload fasta file`,
            title: ' ',
            duration: 2000,
            icon: ' ',
            type: 'error'
          });
        }
      }
      return null;
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();
    },
    onSend() {
      if (this.textarea.length > 0) {
        this.upload = true;
        this.isTextarea = true;
      }
    },
    onFileProgress() {
      if (this.file === null && this.textarea.length < 1) {
        return;
      }

      if (this.type === 'encode') {
        this.$webSocketsConnect('/api/v1/jobs/ws/encode-file');
        bus.$on('onopen', () => {
          if (this.isTextarea) {
            this.$webSocketsSend(
              JSON.stringify({ is_file: false, payload: this.textarea })
            );
          } else {
            this.$webSocketsSend(
              JSON.stringify({ is_file: true, filename: this.file.name })
            );
            this.$webSocketsSend(this.file);
          }
          this.$router.push({
            name: 'ProcessingPage',
            query: { hash: new Date().getTime(), type: this.type }
          });
        });
      } else if (this.type === 'decode' && this.file !== null) {
        this.$webSocketsConnect('/api/v1/jobs/ws/decode-file');
        bus.$on('onopen', () => {
          this.$webSocketsSend(this.file);
          this.$router.push({
            name: 'ProcessingPage',
            query: { hash: new Date().getTime(), type: this.type }
          });
        });
      }
      return null;
    },
    back() {
      this.upload = false;
      this.file = {};
      this.textarea = '';
    }
  },
  created() {
    if (window.uploadFile) {
      this.upload = true;
      this.file = window.uploadFile || null;
    }
  },
  beforeDestroy() {
    bus.$off('onopen');
  },
  destroyed() {
    this.upload = false;
    this.isTextarea = false;
    this.file = {};
    if (window.uploadFile) {
      window.uploadFile = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-page {
  margin-bottom: 93px;
  &__container {
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;

    margin: 0 10vw;
    padding: 50px 0;
    min-width: 460px;

    @media (max-width: 767px) {
      max-width: 100%;
      min-width: unset;
      margin: 0 10px;
    }

    .headline {
      font-family: var(--headline-font-family);
    }

    .block {
      font-family: var(--block-font-family);
    }

    .box {
      padding-top: 0;
      &__text {
        margin-bottom: 105px;

        @media (max-width: 420px) {
          margin-bottom: 0;
        }
      }

      @media (min-width: 768px) and (max-width: 1089px) {
        padding: 0 75px;
      }

      @media (max-width: 1089px) {
        flex-direction: column;
      }
    }
    .left {
      width: 300px;
      margin-left: 60px;
      margin-right: 62px;
      font-family: var(--headline-font-family);
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      text-transform: uppercase;

      color: var(--kern-blue);

      @media (max-width: 1089px) {
        margin-left: 0;
      }

      @media (max-width: 767px) {
        margin: auto;
        text-align: center;
      }
    }

    .right {
      width: 100%;
      margin-right: 61px;

      @media (max-width: 767px) {
        margin-right: 0;
      }

      ::v-deep .v-btn {
        margin: 0 26px 0;
        width: 274px;
        padding: 0;

        @media (max-width: 1089px) {
          margin-top: 26px;
          margin-left: 0;
        }

        @media (max-width: 767px) {
          margin: 0 auto;
          display: flex;
          &.file-button {
            margin: 37px auto 0;
          }
        }
      }

      .info {
        margin: 0 16px;

        @media (min-width: 768px) and (max-width: 1089px) {
          margin-left: 0;
        }
      }
    }

    .textarea-box {
      padding: 0 16px;

      @media (min-width: 768px) and (max-width: 1089px) {
        padding: 33px 0 0;
      }
      @media (max-width: 767px) {
        padding: 29px 16px 0;
      }
    }
    .textarea {
      background: #f2f2f6;
      height: 100% !important;
      border-radius: 4px;
      width: 100%;
      margin-bottom: 42px;
      padding: 16px 27px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;

      color: #5d5d5d;
      height: 60px;
      resize: vertical;
      min-height: 60px;

      @media (min-width: 767px) and (max-width: 1089px) {
        margin-bottom: 0;
      }
    }
    label {
      margin-top: 26px;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 26px;

      display: flex;
      align-items: center;

      color: #8a8a8a;
    }
    .v-btn {
      background: #f1505f;
      border-radius: 10px;
      font-style: normal;
      font-weight: bold;
      line-height: 26px;
      text-transform: uppercase;

      color: #ffffff;

      font-size: 16px;
      width: 180px;
      height: 50px;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .divline {
      height: 1px;
      text-align: left;
      border: 1px solid #d4d4d4;

      margin: 50px 10%;
      text-align: center;
    }
    .or {
      background-color: white;
      position: relative;
      left: 0px;
      top: -0.7em;

      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      line-height: 26px;
      align-items: center;
      text-transform: uppercase;

      color: #8a8a8a;

      padding: 0px 20px;
      font-size: 20px;
    }

    .button-send {
      margin-bottom: 84px;
      width: 220px;
    }

    .example {
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  .case-2 {
    padding-bottom: 101px;

    @media (max-width: 1089px) {
      padding-bottom: 77px;
    }
    h1 {
      font-family: Karla;
      text-align: center;
      margin-top: 86px;
      margin-bottom: 46px;

      @media (max-width: 1089px) {
        margin-top: 26px;
      }
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }

    .about-input {
      display: flex;
      justify-content: center;

      @media (max-width: 1089px) {
        margin: 0 40px;
        text-align: center;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        margin: 0 17px;
      }

      &__title,
      &__type,
      &__size {
        font-family: Source Sans Pro;
        font-style: normal;
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;
      }

      &__title {
        color: #8a8a8a;
        margin-right: 50px;

        @media (max-width: 767px) {
          margin-right: 0;
          text-align: center;
          margin-bottom: 20px;
        }
      }

      &__type {
        color: var(--kern-blue);
        font-weight: bold;
        margin-right: 40px;

        @media (max-width: 767px) {
          margin: 0 auto;
          text-align: center;
        }
      }

      &__size {
        color: var(--kern-blue);

        @media (max-width: 767px) {
          margin-top: 20px;
          text-align: center;
        }
      }
    }

    .wrapper-button {
      display: flex;
      justify-content: center;
      margin-top: 58px;

      @media (max-width: 767px) {
        margin: 42px 17px 0;
      }
    }
  }
}
</style>
